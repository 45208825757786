<div *ngIf="(ticket$ |async) as ticket">
    <div *ngIf="(messageOrigine$ |async) as messageOrigine">
        <mat-card appearance="outlined" class ="centered">
        <div class ="div-container-between">
            <div class="div-container-left">
                <div class="infoUser">
                    <!-- Infos personnel -->
                    <div><a matTooltip = "ouvre la fiche de {{messageOrigine.utilisateur.prenom}} {{messageOrigine.utilisateur.nom}}" 
                            class = "button-link" 
                            routerLink="/app/profil/{{messageOrigine.utilisateur.id}}"
                            target="_blank"
                        >
                            {{messageOrigine.utilisateur.prenom}} {{messageOrigine.utilisateur.nom}}
                        </a>
                    </div>
                    <div>{{messageOrigine.utilisateur.agrement_ve}}</div>
                    <!-- infos ticket -->
                    <div    *ngIf="ticket.Entite!.raison_sociale!=''" 
                            class = "button-link" 
                            style="margin-top: 10px;">
                        <a  matTooltip="Ouvre la fiche de {{ticket.Entite?.raison_sociale}}" 
                            routerLink="/app/entite/{{ticket.Entite?.id}}" 
                            target="_blank">
                                {{ticket.Entite?.raison_sociale}}
                        </a>
                    </div>
                </div>
                <div>
                    <!-- Titre et texte -->
                    <mat-card-title>
                        <span [ngClass]="this.apiSupportService.getStateClass(ticket.Etat!.code)">
                            <mat-icon >
                                <span class="material-symbols-outlined">{{this.apiSupportService.getTicketStateIcon(ticket.Etat!.code)}}
                                </span>
                            </mat-icon>
                            {{ticket.Etat!.libelle}}
                        </span>
                        - {{ticket.Type?.libelle}} - ticket n° {{messageOrigine.tck_mss_tck_id}} - {{ticket.description}}
                    </mat-card-title>
                    
                    <div *ngIf="ticket.immatriculation!=''">
                        <a  class = "button-link" 
                            matTooltip="Ouvre l'historique du véhicule avec l'immatriculation {{ticket.immatriculation}}" 
                            routerLink="/app/historiquevehicule/{{ticket.immatriculation}}"
                            target="_blank">
                            {{ticket.immatriculation}}
                        </a>
                    </div>
                    <mat-card-subtitle class="date">
                        <div class="div-container-left">
                            <div>
                                <div class="tckNouveau">ticket ouvert le</div>
                                <div *ngIf="ticket?.date_premiere_lecture!='0000-00-00T00:00:00.000'" class="lule">ticket lu le</div>
                                <div *ngIf="ticket.Etat!.code === this.apiSupportService.CONST_CODE_ETAT_TICKET_CLOTURE" class="tckCloture">ticket clôturé le</div>
                            </div>
                            <div>
                                <div class="tckNouveau">{{messageOrigine.tck_mss_date | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                                <div *ngIf="ticket?.date_premiere_lecture!='0000-00-00T00:00:00.000'" class="lule">{{ticket.date_premiere_lecture | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                                <div *ngIf="ticket.Etat!.code === this.apiSupportService.CONST_CODE_ETAT_TICKET_CLOTURE" class="tckCloture">{{ticket.listehisto![0].date | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                            </div>
                        </div>   
                    </mat-card-subtitle>
                </div>
            </div>        
        </div>
        <mat-card-content  class=".mat-card-content">
            <hr>
            <div class="contentMessage" style="overflow: auto;" [innerHTML]="sanitizer.bypassSecurityTrustHtml(messageOrigine.tck_mss_message)"></div>
            <!-- PJ -->
            <div class="filesection" *ngIf="messageOrigine.tabpiecejointe.length!=0">
                <mat-icon>attach_file</mat-icon>
                <div>
                    <div *ngFor="let pj of messageOrigine.tabpiecejointe; trackBy:trackById">
                        <a class="file" (click)="dowloadFile(pj.id!,idTicket,pj.nomFichier!)">{{pj.nomFichier}}</a>
                    </div>
                </div>
            </div>
  
            <mat-card-actions>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-left" style="width:100%; display: flex; justify-content: center;">
                        <button 
                            mat-flat-button color="primary" 
                            [disabled]="ticket.Etat!.code === this.apiSupportService.CONST_CODE_ETAT_TICKET_CLOTURE" 
                            (click)="onSetResponse()">
                            <mat-icon class="material-symbols-outlined">
                                edit_square
                            </mat-icon>
                            Ecrire une réponse
                        </button>
                        <button 
                            *ngIf="isAdmin"
                            mat-flat-button color="primary" 
                            [disabled]="ticket.Etat!.code === this.apiSupportService.CONST_CODE_ETAT_TICKET_CLOTURE" 
                            (click)="onSetModele()">
                            <mat-icon class="material-symbols-outlined">
                                description
                            </mat-icon>
                            Sélectionner un modèle de réponse
                        </button>
                        <button 
                            mat-flat-button color="warn" 
                            *ngIf="ticket.Etat!.code != this.apiSupportService.CONST_CODE_ETAT_TICKET_CLOTURE" 
                            (click)="onCloseTicket()">
                            <mat-icon class="material-symbols-outlined">
                                lock
                            </mat-icon>
                            Clôturer le ticket
                        </button>
                        <button 
                            mat-flat-button color="warn" 
                            *ngIf="ticket.Etat!.code === this.apiSupportService.CONST_CODE_ETAT_TICKET_CLOTURE" 
                            (click)="onReOpenTicket()">
                            <mat-icon class="material-symbols-outlined">
                                lock_open
                            </mat-icon>
                            Ré-ouvrir le ticket
                        </button>
                        <button 
                            mat-flat-button color="primary" 
                            (click)="onSetHisto()">
                            <mat-icon 
                                *ngIf="this.voirHisto===true"
                                class="material-symbols-outlined">
                                event_busy
                            </mat-icon>
                            <mat-icon 
                                *ngIf="this.voirHisto!=true"
                                class="material-symbols-outlined">
                                calendar_month
                            </mat-icon>
                            {{getLibelleHisto()}}
                        </button>
                        <button 
                            mat-flat-button color="primary" 
                            (click)="onGetBack()">
                            <mat-icon class="material-symbols-outlined">
                                undo
                            </mat-icon>
                            retour
                        </button>
                    </div>
                </div>
            
            </mat-card-actions>
        </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined" *ngIf="voirHisto===true" class ="centered">
            <mat-table [dataSource]="ticket.listehisto!">
                
                <!-- Etat Column -->
                <ng-container matColumnDef="etat">
                    <mat-header-cell *matHeaderCellDef> Etat </mat-header-cell>
                    <mat-cell [ngClass]="this.apiSupportService.getStateClass(histo.etat.code)" *matCellDef="let histo"><mat-icon ><span class="material-symbols-outlined">{{this.apiSupportService.getTicketStateIcon(histo.etat!.code)}}</span></mat-icon> {{histo.etat.libelle}} </mat-cell>
                </ng-container>
                
                <!-- User Column -->
                <ng-container matColumnDef="user">
                    <mat-header-cell *matHeaderCellDef> Mis à jour par</mat-header-cell>
                    <mat-cell *matCellDef="let histo"><a matTooltip = "ouvre la fiche de {{histo.utilisateur.prenom}} {{histo.utilisateur.nom}}" 
                        class = "button-link" 
                        routerLink="/app/profil/{{histo.utilisateur.id}}"
                        target="_blank"
                    >
                        {{histo.utilisateur.prenom}} {{histo.utilisateur.nom}}
                    </a></mat-cell>
                </ng-container>
                
                <!-- Date Column -->
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef> Le </mat-header-cell>
                    <mat-cell *matCellDef="let histo"> {{histo.date| date: 'dd/MM/yyyy HH:mm:ss'}} </mat-cell>
                </ng-container>
                
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card>

        <app-modele *ngIf="selectModele" (modeleSelectedEvent)="getModele($event)" [modeSelectModele]="true"></app-modele>
        <app-message *ngIf="reponse" [idTicket]="ticket.id!" [modele]="modele" (MessageFinished)="onMessageFinished($event)"></app-message>

        <div *ngFor="let message of ticket.listeMessage; trackBy:trackById">
            <mat-card appearance="outlined" *ngIf="message.tck_mss_origine==false" class="centered">
                <div class ="div-container-between">
                    <div class="div-container-left">
                        <div  class="avatar">
                            <div><a matTooltip = "ouvre la fiche de {{message.utilisateur.prenom}} {{message.utilisateur.nom}}" 
                                class = "button-link" 
                                routerLink="/app/profil/{{message.utilisateur.id}}"
                                target="_blank"
                            >
                                {{message.utilisateur.prenom}} {{message.utilisateur.nom}}
                            </a></div>
                            <div>{{message.utilisateur!.agrement_ve}}</div>
                        </div>
                        <div style="margin-top: 10px;" >
                            {{message.tck_mss_date | date: 'dd/MM/yyyy HH:mm:ss'}}
                            <div class="contentMessage" [innerHTML]="sanitizer.bypassSecurityTrustHtml(message.tck_mss_message)"></div>
                        </div>
                    </div>
                    <div class="filesection" *ngIf="message.tabpiecejointe!.length > 0">
                        <mat-icon>attach_file</mat-icon>
                        <div>
                            <div *ngFor="let pj of message.tabpiecejointe; trackBy:trackById">
                                <a class="file" (click)="dowloadFile(pj.id!,idTicket,pj.nomFichier!)">{{pj.nomFichier}}</a>
                            </div>
                        </div>
                        
                    </div>
                </div>        
            </mat-card>
        </div>
    </div>
</div>