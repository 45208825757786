<mat-card appearance="outlined" *ngIf="modeCreation == true || apiUtilitaire.isMobileFormat() == true ">
      <div>
            <h2>ADELA : Analyse Diagnostics et Expertise des Lubrifiants Automotive</h2>
      </div>
      <div class="content">
            <div class="titlebox">Nouvelle adresse d'expédition de vos échantillons</div>
            <div class="div-container-left">
                  <ul style="list-style-type: none;">
                        <li style="font-weight: 600;">ADELA</li>
                        <li>ZI RUE LAVOISIER</li>
                        <li>BP 813</li>
                        <li>VERNEUIL-SUR-AVRE CEDEX, 27138</li>
                        <li>FRANCE</li>
                  </ul>

                  <div class="label-error">
                        <div class="div-container-left">
                              <mat-icon color="warn" class="icon-warning">error</mat-icon><span style="flex: 1;">À compter du 1er janvier 2021, les échantillons envoyés à l'ancienne adresse seront automatiquement renvoyés à l'expéditeur.</span>
                        </div>
                        <br>
                        <div class="div-container-left">
                              <mat-icon color="warn" class="icon-warning">error</mat-icon><span style="flex: 1;">Pour éviter tout problème d'acheminement de vos échantillons, nous vous invitons à les expédier par COLISSIMO et non par CHRONOPOST, ce service n'assurant pas la distribution dans les boîtes postales.</span>
                        </div>
                  </div>
                  
            </div>
      </div>

      <div class="content">
            <div class="titlebox">Mon dossier d'analyse de fluide</div>
            <mat-stepper orientation="vertical" [ngClass]="{'openUpStepper' : false}" [linear]="isLinear" #stepper>
                  
                  <!-- Référence du dossier -->
                  <mat-step [stepControl]="Etape1FormGroup" [errorMessage]="const_errorMessageStepper">
                        <form [formGroup]="Etape1FormGroup">
                              <ng-template matStepLabel>
                                    <mat-icon class="icon-display-32" color="accent">folder</mat-icon>&nbsp;&nbsp;&nbsp;
                                    <mat-label>Référence du dossier {{ Etape1FormGroup.numEtiquette }}</mat-label>
                              </ng-template>

                              <div class="div-container">
                                    <mat-form-field>
                                          <mat-label>Référence</mat-label>
                                          <input matInput type="text" 
                                                id                = "reference" 
                                                formControlName   = "reference" 
                                                oninput           = "this.value = this.value.toUpperCase().replace(/[^a-zA-Z0-9]/g,'')" 
                                                minlength         = "7"
                                                maxlength         = "24"
                                                >
                                          <mat-hint>Alphanumérique entre 7 et 24 caractères</mat-hint>
                                          <mat-error *ngIf="reference?.hasError('required')">La référence du dossier est obligatoire</mat-error>
                                    </mat-form-field>
      
                                    <mat-form-field>
                                          <mat-label>Cabinet à facturer</mat-label>
                                          <mat-select name="entite" formControlName="entite"
                                          (selectionChange)="onSelectEntite($event)">
                                                <mat-option *ngFor      = "let entite of filterEntitesOfType(allEntite)" 
                                                            [value]     = "entite.id" 
                                                            [disabled]  = "entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE">
                                                      {{ entite.raison_sociale }}
                                                      <span *ngIf="entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE" style="color: red;"> -- Coordonnées bancaires non validées</span>
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="entite?.hasError('required')">La sélection d'un cabinet est obligatoire</mat-error>
                                    </mat-form-field>
                                    
                                    <mat-form-field *ngIf="modeCreation && etiquettes.length > 0">
                                          <mat-label>N° étiquette</mat-label>
                                          <mat-select formControlName="numEtiquette">
                                                <mat-option *ngFor="let etiquette of etiquettes" [value]="etiquette.numero">{{etiquette.numero}}</mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="numEtiquette?.hasError('required')">La sélection d'un numéro d'étiquette est obligatoire</mat-error>
                                    </mat-form-field>
                                    
                                    <mat-form-field *ngIf="modeCreation && etiquettes.length == 0">
                                          <mat-label>N° étiquette</mat-label>
                                          <input matInput formControlName="numEtiquette">
                                          <mat-error *ngIf="numEtiquette?.hasError('required')">Le numéro d'étiquette est obligatoire</mat-error>
                                      </mat-form-field>

                                    <mat-form-field *ngIf="!modeCreation">
                                          <mat-label>N° étiquette</mat-label>
                                          <input matInput formControlName="numEtiquette">
                                      </mat-form-field>
      
                                    <mat-form-field>
                                          <mat-label>Compagnie d'assurance</mat-label>
                                          <mat-select name="compagnie" id="compagnie" formControlName="compagnie" (selectionChange)="onSelectChangeCompagnie($event)">                              
                                                <mat-option *ngFor="let compagnie of compagnies$" [value]="compagnie">
                                                      {{ compagnie.code_gta }} - {{ compagnie.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="compagnie?.hasError('required')">La sélection d'une compagnie d'assurance est obligatoire</mat-error>
                                    </mat-form-field>
                                                                        
                                    <mat-form-field *ngIf="Etape1FormGroup.get('compagnie')?.value?.id == const_autre_compagnie">
                                          <mat-label>Autre compagnie d'assurance</mat-label>
                                          <input matInput type="text" id="autreCompagnie" maxlength="100" formControlName="autreCompagnie" oninput="this.value = this.value.toUpperCase()">
                                          <mat-hint align="end">{{autreCompagnie.value.length}} / 100</mat-hint>
                                          <mat-error *ngIf="autreCompagnie?.hasError('required')">L'autre compagnie d'assurance est obligatoire</mat-error>
                                    </mat-form-field>
                              
                                    <mat-form-field>
                                          <mat-label>Email du destinataire (Expéditeur de la demande)</mat-label>
                                          <input matInput type="mail" id="emailExpert" formControlName="emailExpert">
                                          <mat-error *ngIf="emailExpert?.hasError('required')">L'adresse email obligatoire</mat-error>
                                          <mat-error *ngIf="emailExpert?.hasError('checkValidatorMail')">{{ emailExpert?.errors?.checkValidatorMail }}</mat-error>
                                    </mat-form-field>

                                    <mat-form-field>
                                          <mat-label>Type d'analyse</mat-label>
                                          <mat-select name="typeAnalyse" id="typeAnalyse" formControlName="typeAnalyse" (selectionChange)="onSelectChangeTypeAnalyse($event)">
                                                <mat-option *ngFor="let typeAnalyse of typeAnalyses$" [value]="typeAnalyse">
                                                      {{ typeAnalyse.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="typeAnalyse?.hasError('required')">La sélection d'un type d'analyse est obligatoire</mat-error>
                                    </mat-form-field>
                                    <mat-label class="label-alert">{{ info_bilan_moteur }}</mat-label>

                                    <mat-checkbox formControlName="expertiseJudiciaire" style="margin-top: 15px;">Expertise Judiciaire</mat-checkbox>
                              </div>
                        </form>
                  </mat-step>
                  
                  <!-- Informations générales du véhicule -->
                  <mat-step [stepControl]="Etape2FormGroup" *ngIf="(form_avarie_sur_vehicule || form_huile_neuve || form_bilan_moteur_preventif || form_vehicule_incendie)" [errorMessage]="const_errorMessageStepper">
                        <form [formGroup]="Etape2FormGroup">
                              <ng-template matStepLabel>
                                    <mat-icon class="icon-display-32" color="accent">directions_car_filled</mat-icon>&nbsp;&nbsp;&nbsp;
                                    Informations générales du véhicule
                              </ng-template>
                              <div>
                                    <mat-label *ngIf="form_bilan_moteur_preventif">
                                          <i>Pour rappel, le Bilan Santé Moteur est un contrôle préventif. Ce type d'analyse ne convient absolument pas à une analyse suite à avarie. Veuillez également noter que ce type d'analyse ne comporte pas de diagnostic rédigé.</i>
                                    </mat-label>
                              </div>

                              <div class="div-container">
                                    <mat-form-field>
                                          <mat-label>Immatriculation</mat-label>
                                          <input 
                                                matInput 
                                                type              = "text" 
                                                formControlName   = "immatriculation"
                                                (blur)            = "getInfosVehicule($event)"
                                                [readonly]        = "waitingInfosVehicule"
                                                appImmat
                                          >
                                          <mat-spinner *ngIf="waitingInfosVehicule" matSuffix diameter="24" style="display: inline-flex; margin-right: 10px;"></mat-spinner>
                                          <mat-error *ngIf="immatriculation?.hasError('required')">L'immatriculation d'un type d'analyse est obligatoire</mat-error>
                                    </mat-form-field>                        
                                                      
                                    <mat-form-field>
                                          <mat-label>N° VIN</mat-label>
                                          <input matInput 
                                                  type                = "text" 
                                                  formControlName     = "vin" 
                                                  oninput             = "this.value = this.value.toUpperCase()" 
                                                  maxlength           = "17"
                                                  [readonly]        = "waitingInfosVehicule"
                                                  >
                                          <mat-hint align="start">Alphanumérique de 17 caractères</mat-hint>
                                          <mat-hint align="end">{{vin.value.length}} / 17</mat-hint>
                                          <mat-error *ngIf="vin?.hasError('erreur')">{{ vin?.errors?.erreur }}</mat-error>
                                      </mat-form-field>
                                    
                                    <ng-container *ngIf="(form_avarie_sur_vehicule || form_bilan_moteur_preventif || form_vehicule_incendie)">
                                          <mat-form-field>
                                                <mat-label>Date MEC</mat-label>
                                                <input matInput type="date" id="mec" formControlName="mec" [readonly]        = "waitingInfosVehicule">
                                                <mat-error *ngIf="mec?.hasError('required')">La date de mise en circulation est obligatoire</mat-error>
                                          </mat-form-field>
            
                                          <mat-form-field>
                                                <mat-label>Marque</mat-label>
                                                <input      matInput
                                                            type              ="text"
                                                            id                ="marqueVehicule"
                                                            formControlName   ="marqueVehicule"
                                                            maxlength         ="50"
                                                            oninput           ="this.value = this.value.toUpperCase()"
                                                            [readonly]        ="waitingInfosVehicule">
                                                <mat-hint align="end">{{marqueVehicule.value.length}} / 50</mat-hint>
                                                <mat-error *ngIf="marqueVehicule?.hasError('required')">La marque est obligatoire</mat-error>
                                          </mat-form-field>
            
                                          <mat-form-field>
                                                <mat-label>Modèle</mat-label>
                                                <input matInput type="text" id="modele" formControlName="modele" maxlength="80" oninput="this.value = this.value.toUpperCase()" [readonly]="waitingInfosVehicule">
                                                <mat-hint align="end">{{modele.value.length}} / 80</mat-hint>
                                                <mat-error *ngIf="modele?.hasError('required')">Le modèle est obligatoire</mat-error>
                                          </mat-form-field>
            
                                          <mat-form-field>
                                                <mat-label>Type Moteur</mat-label>
                                                <input matInput type="text" id="typeMoteur" formControlName="typeMoteur" maxlength="50" oninput="this.value = this.value.toUpperCase()" [readonly]="waitingInfosVehicule">
                                                <mat-hint align="end">{{typeMoteur.value.length}} / 50</mat-hint>
                                                <mat-error *ngIf="typeMoteur?.hasError('required')">Le type moteur est obligatoire</mat-error>
                                          </mat-form-field>
            
                                          <mat-form-field >
                                                <mat-label>Energie</mat-label>
                                                <mat-select name="energie" id="energie" formControlName="energie">
                                                      <mat-option *ngFor="let energie of energies$" [value]="energie">
                                                            {{ energie.nom }}
                                                      </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="energie?.hasError('required')">La sélection d'une énergie est obligatoire</mat-error>
                                          </mat-form-field>
            
                                          <mat-form-field>
                                                <mat-label>Usage</mat-label>
                                                <mat-select name="usage" id="usage" formControlName="usage" >
                                                      <mat-option *ngFor="let usage of usages$" [value]="usage">
                                                            {{ usage.nom }}
                                                      </mat-option>
                                                </mat-select>
                                          </mat-form-field>
            
                                          <mat-form-field>
                                                <mat-label>Kms ou Heures</mat-label>
                                                <input matInput type="number" id="kmsHeures" formControlName="kmsHeures" min="0" step="1" [readonly]="waitingInfosVehicule">
                                          </mat-form-field>
            
                                          <mat-form-field>
                                                <mat-label>Capacité Carter</mat-label>
                                                <input matInput type="number" id="capaciteCarter" formControlName="capaciteCarter" min="0.00" step="0.01" [readonly]="waitingInfosVehicule">
                                          </mat-form-field>
                  
                                          <mat-form-field *ngIf="form_vehicule_incendie">
                                                <mat-label>Incendie</mat-label>
                                                <mat-select name="incendie" id="incendie" formControlName="incendie">
                                                      <mat-option *ngFor="let incendie of incendies$" [value]="incendie">
                                                            {{ incendie.nom }}
                                                      </mat-option>
                                                </mat-select>
                                          </mat-form-field>
                                                                                                                         
                                          <mat-form-field>          
                                                <mat-label>Qté (L)</mat-label>                    
                                                <input matInput type="number" id="quantite" formControlName="quantite"  min="0.00" step="0.01" [readonly]="waitingInfosVehicule">
                                                <mat-error *ngIf="quantite?.invalid && (quantite?.dirty || quantite?.touched)">{{ quantite.errors?.erreur }}</mat-error>
                                          </mat-form-field>

                                          <mat-checkbox id="appoint" formControlName="appoint" (change)="isEnabledQuantite($event.checked)">Appoints</mat-checkbox>
                                    </ng-container>                          
                              </div>
                              
                              <div class="div-button-right" *ngIf="modeCreation && !waitingInfosVehicule">
                                    <button mat-flat-button matStepperPrevious type="button" color="basic"><mat-icon>west</mat-icon><span class="span-button">Précédent</span></button>
                                    <button mat-flat-button 
                                          matStepperNext 
                                          color="primary"
                                          [disabled]="Etape2FormGroup.invalid"><span class="span-button">Suivant</span><mat-icon>east</mat-icon></button>
                              </div>
                        </form>
                  </mat-step>
                  
                  <!-- Identification du fluide -->
                  <mat-step [stepControl]="Etape3FormGroup" [errorMessage]="const_errorMessageStepper">
                        <form [formGroup]="Etape3FormGroup">
                              <ng-template matStepLabel>
                                    <mat-icon class="icon-display-32" color="accent">science</mat-icon>&nbsp;&nbsp;&nbsp;
                                    Identification du fluide
                              </ng-template>
                              
                              <div class="div-container">
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Type de Fluide</mat-label>
                                          <mat-select name="typeFluide" id="typeFluide" formControlName="typeFluide" (selectionChange)="onSelectChangeTypeFluide($event)">
                                                <mat-option *ngFor="let typeFluide of typeFluides$" [value]="typeFluide">
                                                      {{ typeFluide.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="typeFluide?.hasError('required')">La sélection d'un type de fluide est obligatoire</mat-error>
                                    </mat-form-field>
                                    
                                    <ng-container *ngIf="form_avarie_sans_vehicule && select_huile_en_service"><i>Le fluide 'Huile en service' n'est pas autorisé pour un contrôle produit. En effet, si c'est une huile en service alors c'est un contrôle sur véhicule.</i></ng-container>
                                    
                                    <mat-form-field>
                                          <mat-label>Grade SAE</mat-label>
                                          <input matInput type="text" id="gradeSAE" formControlName="gradeSAE" maxlength="20" oninput="this.value = this.value.toUpperCase()">
                                          <mat-hint align="end">{{gradeSAE.value.length}} / 50</mat-hint>
                                          <mat-error *ngIf="gradeSAE?.hasError('required')">Le grade SAE est obligatoire</mat-error>
                                    </mat-form-field> 
                                    
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Combustible</mat-label>
                                          <mat-select name="combustible" id="combustible" formControlName="combustible">
                                                <mat-option *ngFor="let combustible of combustibles$" [value]="combustible">
                                                      {{ combustible.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="combustible?.hasError('required')">La sélection d'un combustible est obligatoire</mat-error>                              
                                    </mat-form-field>
            
                                    <mat-form-field>
                                          <mat-label>Marque du fluide</mat-label>
                                          <input matInput type="text" id="marqueFluide" formControlName="marqueFluide" maxlength="50" oninput="this.value = this.value.toUpperCase()">
                                          <mat-hint align="end">{{marqueFluide.value.length}} / 50</mat-hint>
                                          <mat-error *ngIf="marqueFluide?.hasError('required')">La marque du fluide est obligatoire</mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field>
                                          <mat-label>Type Commercial</mat-label>
                                          <input matInput type="text" id="typeCommercial" formControlName="typeCommercial" maxlength="50" oninput="this.value = this.value.toUpperCase()">
                                          <mat-hint align="end">{{typeCommercial.value.length}} / 50</mat-hint>
                                          <mat-error *ngIf="typeCommercial?.invalid && (typeCommercial?.dirty || typeCommercial?.touched)">{{ typeCommercial.errors?.erreur }}</mat-error>
                                    </mat-form-field> 
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Organe de Prélèvement</mat-label>
                                          <mat-select name="organePrelevement" id="organePrelevement" formControlName="organePrelevement" (selectionChange)="onSelectChangeOrganePrelevement()">
                                                <mat-option *ngFor="let organePrelevement of organesPrelevement$" [value]="organePrelevement" [disabled]="form_bilan_moteur_preventif && organePrelevement.code != this.apiAdela.CONST_ORGANE_PRELEV_MOTEUR">
                                                      {{ organePrelevement.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="organePrelevement?.hasError('required')">La sélection d'un organe de prélèvement est obligatoire</mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Lieu de Prélèvement</mat-label>
                                          <mat-select name="lieuPrelevement" id="lieuPrelevement" formControlName="lieuPrelevement">
                                                <mat-option *ngFor="let lieuPrelevement of lieuxPrelevement$" [value]="lieuPrelevement">
                                                      {{ lieuPrelevement.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="lieuPrelevement?.hasError('required')">La sélection d'un lieu de prélèvement est obligatoire</mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Température de Prélèvement</mat-label>
                                          <mat-select name="temperaturePrelevement" id="temperaturePrelevement" formControlName="temperaturePrelevement">
                                                <mat-option *ngFor="let temperaturePrelevement of temperaturesPrelevement$" [value]="temperaturePrelevement">
                                                      {{ temperaturePrelevement.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="temperaturePrelevement?.hasError('required')">La sélection d'une température de prélèvement est obligatoire</mat-error>                              
                                    </mat-form-field>
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Date de Prélèvement</mat-label>
                                          <input matInput type="date" id="datePrelevement" formControlName="datePrelevement">
                                          <mat-error *ngIf="datePrelevement?.hasError('erreur')">{{ datePrelevement.errors?.erreur }}</mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Kms ou Heures depuis la dernière vidange</mat-label>
                                          <input matInput type="number" id="kmsHeures" formControlName="kmsHeures" min="0" step="1">
                                    </mat-form-field>                  
                              </div>
                              <div class="div-button-right" *ngIf="modeCreation">
                                    <button mat-flat-button matStepperPrevious type="button" color="basic"><mat-icon>west</mat-icon><span class="span-button">Précédent</span></button>
                                    <button     mat-flat-button 
                                                matStepperNext 
                                                color="primary"
                                                [disabled]="Etape3FormGroup.invalid"><span class="span-button">Suivant</span><mat-icon>east</mat-icon></button>
                              </div>
                        </form>
                  </mat-step>
            
                  <!-- Commentaire ou objet de la demande -->
                  <mat-step [stepControl]="Etape4FormGroup" [errorMessage]="const_errorMessageStepper">
                        <form [formGroup]="Etape4FormGroup">
                              <ng-template matStepLabel>
                                    <mat-icon class="icon-display-32" color="accent">comment</mat-icon>&nbsp;&nbsp;&nbsp;
                                    Commentaire ou objet de la demande
                              </ng-template>
      
                              <mat-form-field style="max-width: 100%" class="textarea">
                                    <mat-label>Commentaires</mat-label>
                                    <textarea matInput 
                                                formControlName   = "commentaire" 
                                                rows              = "4"
                                                ></textarea>
                                    <mat-error *ngIf="commentaire?.invalid && (commentaire?.dirty || commentaire?.touched)">{{ commentaire.errors?.erreur }}</mat-error>
                              </mat-form-field>
                              <!-- EMPLACEMENT ANCIEN UPLOAD -->
                              
                              <div class="div-container-between">
                                    <app-upload-files   (uploadedFiles)  = "getFiles($event)"
                                          [uploadParameter]="uploadParameter"
                                          >
                                    </app-upload-files>
                                    
                                    <div *ngIf="form_adela.tab_PiecesJointes">
                                          <div class = "pjTitle">Pièces jointes :</div>
                                          <div  *ngFor="let file of form_adela.tab_PiecesJointes">
                                              
                                              <a class= "linked" (click)="dowloadFile(file.name)">
                                                  {{file.name}}
                                                  <br>
                                              </a>
                                                                                              
                                          </div>
                                    </div>

                                    <div class="div-button-right" *ngIf="modeCreation">
                                          <button mat-flat-button matStepperPrevious type="button" color="basic"><mat-icon>west</mat-icon><span class="span-button">Précédent</span></button>
                                          <button     mat-flat-button 
                                                      matStepperNext 
                                                      color="primary"
                                                      [disabled]="Etape4FormGroup.invalid"><span class="span-button">Suivant</span><mat-icon>east</mat-icon></button>
                                    </div>
                              </div>
      
                        </form>
                  </mat-step>
      
                  <!-- Analyse à effectuer -->
                  <mat-step [stepControl]="Etape5FormGroup" [errorMessage]="const_errorMessageStepper">
                        <form [formGroup]="Etape5FormGroup">
      
                        <ng-template matStepLabel>
                              <mat-icon class="icon-display-32" color="accent">analytics</mat-icon>&nbsp;&nbsp;&nbsp;
                              Analyse à effectuer
                        </ng-template>
                              <mat-radio-group formControlName="analyse">
                                    <table mat-table matTableResponsive [dataSource]="dataSource" class="table-primary">
                                          <!-- Selected Column -->
                                          <ng-container matColumnDef="selecteur">
                                                <th mat-header-cell *matHeaderCellDef class="premiere-colonne"></th>
                                                <td mat-cell *matCellDef="let analyse" class="td-premiere-colonne"><mat-radio-button [value]="analyse.id"></mat-radio-button></td>
                                          </ng-container>
      
                                          <!-- Analyse Column -->
                                          <ng-container matColumnDef="categorie">
                                                <th mat-header-cell *matHeaderCellDef> Analyse </th>
                                                <td mat-cell *matCellDef="let analyse"> {{analyse.categorie}} </td>
                                          </ng-container>
      
                                          <!-- Détail Column -->
                                          <ng-container matColumnDef="designation">
                                                <th mat-header-cell *matHeaderCellDef> Détail </th>
                                                <td mat-cell *matCellDef="let analyse"> {{analyse.designation}} </td>
                                          </ng-container>
                        
                                          <!-- Tarif Column -->
                                          <ng-container matColumnDef="tarif">
                                                <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Tarif </th>
                                                <td mat-cell *matCellDef="let analyse" style="text-wrap: nowrap;"> {{analyse.tarif}} € </td>
                                          </ng-container>
                       
                                          <tr mat-header-row *matHeaderRowDef="displayedColumnsAnalyseAEffectuer"></tr>
                                          <tr mat-row *matRowDef="let row; columns: displayedColumnsAnalyseAEffectuer;"></tr>
                                    </table>
                              </mat-radio-group>
      
                              <br>
                              <div class="div-button-right" *ngIf="modeCreation">
                                    <button mat-flat-button matStepperPrevious type="button" color="basic"><mat-icon>west</mat-icon><span class="span-button">Précédent</span></button>                                                            
                                    <div class="button-container">                                    
                                          <button     *ngIf="!waiting_Save"
                                                      mat-flat-button 
                                                      matStepperNext 
                                                      style="text-align: center;" 
                                                      color="primary" 
                                                      [disabled]="Etape1FormGroup.invalid || (Etape2FormGroup.invalid && (form_avarie_sur_vehicule || form_huile_neuve || form_bilan_moteur_preventif || form_vehicule_incendie)) || Etape3FormGroup.invalid || Etape4FormGroup.invalid || Etape5FormGroup.invalid" 
                                                      (click)="enregistrerAnalyse()">
                                            <mat-icon>done</mat-icon>
                                            <span class="span-button">Valider ma demande</span>
                                          </button>
                                    </div>
                                    <mat-spinner *ngIf="waiting_Save" diameter="24" ></mat-spinner>
                              </div>                        
                        </form>
                  </mat-step>            
      
                  <mat-step *ngIf="form_confirmation_analyse || form_lecture_seule_analyse_retenue" [errorMessage]="const_errorMessageStepper">
                        <form [formGroup]="Etape6FormGroup">
      
                              <ng-template matStepLabel>
                                    <mat-icon class="icon-display-32" color="accent">analytics</mat-icon>&nbsp;&nbsp;&nbsp;
                                    Analyse retenue
                              </ng-template>
                                    <mat-radio-group formControlName="analyseRetenue">
                                          <table mat-table matTableResponsive [dataSource]="dataSource2" class="table-primary">
                                                <!-- Selected Column -->
                                                <ng-container matColumnDef="selecteur">
                                                      <th mat-header-cell *matHeaderCellDef class="premiere-colonne"></th>
                                                      <td mat-cell *matCellDef="let analyseRetenue" class="td-premiere-colonne"><mat-radio-button [value]="analyseRetenue.id" class="td-premiere-colonne"></mat-radio-button></td>
                                                </ng-container>
            
                                                <!-- Analyse Column -->
                                                <ng-container matColumnDef="categorie">
                                                      <th mat-header-cell *matHeaderCellDef> Analyse </th>
                                                      <td mat-cell *matCellDef="let analyseRetenue"> {{analyseRetenue.categorie}} </td>
                                                </ng-container>
      
                                                <!-- Détail Column -->
                                                <ng-container matColumnDef="designation">
                                                      <th mat-header-cell *matHeaderCellDef> Détail </th>
                                                      <td mat-cell *matCellDef="let analyseRetenue"> {{analyseRetenue.designation}} </td>
                                                </ng-container>
      
                                                <!-- Type analyse -->
                                                 <ng-container matColumnDef="typeAnalyse">
                                                      <th mat-header-cell *matHeaderCellDef> Type d'analyse </th>
                                                      <td mat-cell *matCellDef="let analyseRetenue"> {{getTypeAnalyse(analyseRetenue.idTypeAnalyse)}} </td>
                                                </ng-container>
      
                                                <!-- fluide Column -->
                                                <ng-container matColumnDef="fluide">
                                                      <th mat-header-cell *matHeaderCellDef> Type de fluide </th>
                                                      <td mat-cell *matCellDef="let analyseRetenue"> {{getTypeFluide(analyseRetenue.idFluide)}} </td>
                                                </ng-container>
      
                                                <!-- Organe de prélèvement Column -->
                                                <ng-container matColumnDef="Organe">
                                                      <th mat-header-cell *matHeaderCellDef> Organe de prélèvement </th>
                                                      <td mat-cell *matCellDef="let analyseRetenue"> {{getOrganePrelevement(analyseRetenue.idOrgane)}} </td>
                                                </ng-container>
                              
                                                <!-- Tarif Column -->
                                                <ng-container matColumnDef="tarif">
                                                      <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Tarif </th>
                                                      <td mat-cell *matCellDef="let analyseRetenue" style="text-wrap: nowrap;"> {{analyseRetenue.tarif}} € </td>
                                                </ng-container>
                             
                                                <tr mat-header-row *matHeaderRowDef="displayedColumnsAnalyseRetenue"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumnsAnalyseRetenue;"></tr>
                                          </table>
                                    </mat-radio-group>
            
                                    <br>
                                    <div class="div-button-right" *ngIf="form_confirmation_analyse" style="gap: 8px;">
                                          <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted)">                                          
                                                <button mat-flat-button 
                                                            matStepperNext 
                                                            style       = "text-align: center;" 
                                                            color       = "warn" 
                                                            [disabled]  = "waitingRefused" 
                                                            (click)     = "refuserDemandeADELA()">
                                                  <mat-icon>close</mat-icon>
                                                  <span class="span-button">Refuser la demande</span>
                                                </button>
                                          </div>                                    
                                          <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted)">                                          
                                                <button mat-flat-button 
                                                            matStepperNext 
                                                            style       = "text-align: center;" 
                                                            color       = "primary" 
                                                            [disabled]  = "waitingAccepted || !Etape6FormGroup.valid" (click)="validerDemandeADELA()">
                                                  <mat-icon>done</mat-icon>
                                                  <span class="span-button">Envoyer code VERNOLAB</span>
                                                </button>
                                          </div>
                                          <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted)">                                          
                                                <button     mat-flat-button 
                                                            matStepperNext 
                                                            style       = "text-align: center;" 
                                                            color       = "primary" 
                                                            [disabled]  = "waitingModified || !Etape6FormGroup.valid" 
                                                            (click)     = "modifierDemandeADELA()">
                                                  <mat-icon>save</mat-icon>
                                                  <span class="span-button">Enregistrer les modifications</span>
                                                </button>
                                          </div>    
                                          <mat-spinner diameter="24" *ngIf="waitingModified || waiting_Save || waitingRefused || waitingAccepted"></mat-spinner>                                
                                    </div>                        
                              </form>
                  </mat-step>
                  
                  <mat-step *ngIf="!form_bilan_moteur_preventif && form_resultat_analyse" [errorMessage]="const_errorMessageStepper">
                        <ng-template matStepLabel>
                              <mat-icon class="icon-display-32" color="accent">event</mat-icon>&nbsp;&nbsp;&nbsp;
                              Date de réception de l'échantillon
                        </ng-template>
                        <form [formGroup]="Etape7FormGroup">
                              <mat-form-field>
                                    <mat-label>Date réception échantillon</mat-label>
                                    <input matInput type="date" id="depot_flacon" formControlName="depot_flacon" readonly>
                              </mat-form-field>
                        </form>
                  </mat-step>
            </mat-stepper>
            <mat-card-actions align="end">
                  <button mat-raised-button color="primary" (click)="closeDossier(false, false)">Fermer</button>
            </mat-card-actions>
      </div>
</mat-card>

<mat-card appearance="outlined" *ngIf="modeCreation == false && !apiUtilitaire.isMobileFormat()" class="mat-mdc-card-edition">
      <div class="content">
            <div class="titlebox">Références du dossier</div>
            <div class="div-container-left">
                  <form [formGroup]="Etape1FormGroup" style="width: 100%;">
                        <mat-form-field>
                              <mat-label>Référence</mat-label>
                              <input matInput type="text" formControlName="reference">
                              <mat-hint>Alphanumérique entre 7 et 24 caractères</mat-hint>
                              <mat-error *ngIf="reference?.hasError('required')">La référence du dossier est obligatoire</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                              <mat-label>Cabinet à facturer</mat-label>
                              <mat-select name="entite" formControlName="entite" (selectionChange)="onSelectEntite($event)">
                                    <mat-option *ngFor      = "let entite of filterEntitesOfType(allEntite)" 
                                                [value]     = "entite.id" 
                                                [disabled]  = "entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE">
                                          {{ entite.raison_sociale }}
                                          <span *ngIf="entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE" style="color: red;"> -- Coordonnées bancaires non validées</span>
                                    </mat-option>
                                    <mat-error *ngIf="entite?.hasError('required')">La sélection d'un cabinet est obligatoire</mat-error>
                              </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                              <mat-label>Téléphone du cabinet</mat-label>
                              <input matInput type="text" formControlName="telephoneEntite">
                        </mat-form-field>
                              
                        <mat-form-field>
                              <mat-label>N° étiquette</mat-label>
                              <input matInput formControlName="numEtiquette">
                              <mat-error *ngIf="numEtiquette?.hasError('required')">Le numéro d'étiquette est obligatoire</mat-error>
                          </mat-form-field>
      
<!--                           <mat-form-field>
                              <mat-label>Compagnie d'assurance</mat-label>
                              <mat-select name="compagnie" id="compagnie" formControlName="compagnie" (selectionChange)="onSelectChangeCompagnie($event)">                              
                                    <mat-option *ngFor="let compagnie of compagnies$" [value]="compagnie">
                                          {{ compagnie.code_gta }} - {{ compagnie.nom }}
                                    </mat-option>
                              </mat-select>
                              <mat-error *ngIf="compagnie?.hasError('required')">La sélection d'une compagnie d'assurance est obligatoire</mat-error>
                        </mat-form-field>
                                                            
                        <mat-form-field *ngIf="Etape1FormGroup.get('compagnie')?.value?.id == const_autre_compagnie">
                              <mat-label>Autre compagnie d'assurance</mat-label>
                              <input matInput type="text" id="autreCompagnie" maxlength="100" formControlName="autreCompagnie" oninput="this.value = this.value.toUpperCase()">
                              <mat-hint align="end">{{autreCompagnie.value.length}} / 100</mat-hint>
                              <mat-error *ngIf="autreCompagnie?.hasError('required')">L'autre compagnie d'assurance est obligatoire</mat-error>
                        </mat-form-field> -->
                        
                        <mat-form-field>
                              <mat-label>Expert</mat-label>
                              <input matInput type="text" formControlName="expert">
                        </mat-form-field>

                        <mat-form-field>
                              <mat-label>Téléphone de l'expert</mat-label>
                              <input matInput type="text" formControlName="telephoneExpert">
                        </mat-form-field>

                        <mat-form-field>
                              <mat-label>Email du destinataire (Expéditeur de la demande)</mat-label>
                              <input matInput type="mail" id="emailExpert" formControlName="emailExpert">
                              <mat-error *ngIf="emailExpert?.hasError('required')">L'adresse email obligatoire</mat-error>
                              <mat-error *ngIf="emailExpert?.hasError('checkValidatorMail')">{{ emailExpert?.errors?.checkValidatorMail }}</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                              <mat-label>Type d'analyse</mat-label>
                              <mat-select name="typeAnalyse" id="typeAnalyse" formControlName="typeAnalyse" (selectionChange)="onSelectChangeTypeAnalyse($event)">
                                    <mat-option *ngFor="let typeAnalyse of typeAnalyses$" [value]="typeAnalyse">
                                          {{ typeAnalyse.nom }}
                                    </mat-option>
                              </mat-select>
                              <mat-error *ngIf="typeAnalyse?.hasError('required')">La sélection d'un type d'analyse est obligatoire</mat-error>
                        </mat-form-field>
                  </form>                  
            </div>
      </div>      
      <div class="content" *ngIf="!form_avarie_sans_vehicule">
            <div class="titlebox">Informations générales du véhicule</div>
            <div class="div-container-left">
                  <form [formGroup]="Etape2FormGroup" style="width: 100%;">
                        <div *ngIf="form_bilan_moteur_preventif">
                              <mat-label>
                                    <i>Pour rappel, le Bilan Santé Moteur est un contrôle préventif. Ce type d'analyse ne convient absolument pas à une analyse suite à avarie. Veuillez également noter que ce type d'analyse ne comporte pas de diagnostic rédigé.</i>
                              </mat-label>
                        </div>

                        <mat-form-field>
                              <mat-label>Immatriculation</mat-label>
                              <input 
                                    matInput 
                                    type              = "text" 
                                    formControlName   = "immatriculation"
                                    (blur)            = "getInfosVehicule($event)"
                                    appImmat
                                    [readonly]        = "waitingInfosVehicule"
                              >
                              <mat-spinner *ngIf="waitingInfosVehicule" matSuffix diameter="24" style="display: inline-flex; margin-right: 10px;"></mat-spinner>
                              <mat-error *ngIf="immatriculation?.hasError('required')">L'immatriculation d'un type d'analyse est obligatoire</mat-error>
                        </mat-form-field>                        
                                                
                        <mat-form-field>
                              <mat-label>N° VIN</mat-label>
                              <input matInput 
                                          type                = "text" 
                                          formControlName     = "vin" 
                                          oninput             = "this.value = this.value.toUpperCase()" 
                                          maxlength           = "17"
                                          [readonly]        = "waitingInfosVehicule"
                                          >
                                  <mat-hint align="start">Alphanumérique de 17 caractères</mat-hint>
                                  <mat-hint align="end">{{vin.value.length}} / 17</mat-hint>
                                  <mat-error *ngIf="vin?.hasError('erreur')">{{ vin?.errors?.erreur }}</mat-error>
                              </mat-form-field>
                        
                        <ng-container *ngIf="(form_avarie_sur_vehicule || form_bilan_moteur_preventif || form_vehicule_incendie)">
                              <mat-form-field>
                                    <mat-label>Date MEC</mat-label>
                                    <input matInput type="date" id="mec" formControlName="mec" [readonly]        = "waitingInfosVehicule">
                                    <mat-error *ngIf="mec?.hasError('required')">La date de mise en circulation est obligatoire</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Marque</mat-label>
                                    <input
                                          matInput
                                          type="text"
                                          id="marqueVehicule"
                                          formControlName="marqueVehicule"
                                          maxlength="50"
                                          oninput="this.value = this.value.toUpperCase()"
                                          [readonly]        ="waitingInfosVehicule">
                                    <mat-hint align="end">{{marqueVehicule.value.length}} / 50</mat-hint>
                                    <mat-error *ngIf="marqueVehicule?.hasError('required')">La marque est obligatoire</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Modèle</mat-label>
                                    <input matInput type="text" id="modele" formControlName="modele" maxlength="80" oninput="this.value = this.value.toUpperCase()" [readonly]="waitingInfosVehicule">
                                    <mat-hint align="end">{{modele.value.length}} / 80</mat-hint>
                                    <mat-error *ngIf="modele?.hasError('required')">Le modèle est obligatoire</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Type Moteur</mat-label>
                                    <input matInput type="text" id="typeMoteur" formControlName="typeMoteur" maxlength="50" oninput="this.value = this.value.toUpperCase()" [readonly]="waitingInfosVehicule">
                                    <mat-hint align="end">{{typeMoteur.value.length}} / 50</mat-hint>
                                    <mat-error *ngIf="typeMoteur?.hasError('required')">Le type moteur est obligatoire</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Energie</mat-label>
                                    <mat-select name="energie" id="energie" formControlName="energie">
                                          <mat-option *ngFor="let energie of energies$" [value]="energie">
                                                {{ energie.nom }}
                                          </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="energie?.hasError('required')">La sélection d'une énergie est obligatoire</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Usage</mat-label>
                                    <mat-select name="usage" id="usage" formControlName="usage">
                                          <mat-option *ngFor="let usage of usages$" [value]="usage">
                                                {{ usage.nom }}
                                          </mat-option>
                                    </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Kms ou Heures</mat-label>
                                    <input matInput type="number" id="kmsHeures" formControlName="kmsHeures" min="0" step="1">
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Capacité Carter</mat-label>
                                    <input matInput type="number" id="capaciteCarter" formControlName="capaciteCarter" min="0.00" step="0.01">
                              </mat-form-field>
      
                              <mat-form-field *ngIf="form_vehicule_incendie">
                                    <mat-label>Incendie</mat-label>
                                    <mat-select name="incendie" id="incendie" formControlName="incendie">
                                          <mat-option *ngFor="let incendie of incendies$" [value]="incendie">
                                                {{ incendie.nom }}
                                          </mat-option>
                                    </mat-select>
                              </mat-form-field>
                              
                              <mat-checkbox id="appoint" formControlName="appoint" (change)="isEnabledQuantite($event.checked)">Appoints</mat-checkbox>
                              <mat-form-field>
                                    <mat-label>Qté (L)</mat-label>                    
                                    <input matInput type="number" id="quantite" formControlName="quantite"  min="0.00" step="0.01">
                                    <mat-error *ngIf="quantite?.invalid && (quantite?.dirty || quantite?.touched)">{{ quantite.errors?.erreur }}</mat-error>
                              </mat-form-field>
                        </ng-container>                          
                  </form>
            </div>
      </div>

      <div class="div-container-left" style="align-items:stretch"> 
            <div style="width: 100%; display: flex;">
                  <div class="content" style="flex: 1;">
                        <div class="titlebox">Identification du fluide</div>
                        <div class="div-container">
                              <form [formGroup]="Etape3FormGroup" style="width: 100%;">                                              
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Type de Fluide</mat-label>
                                          <mat-select name="typeFluide" id="typeFluide" formControlName="typeFluide" (selectionChange)="onSelectChangeTypeFluide($event)">
                                                <mat-option *ngFor="let typeFluide of typeFluides$" [value]="typeFluide">
                                                      {{ typeFluide.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="typeFluide?.hasError('required')">La sélection d'un type de fluide est obligatoire</mat-error>
                                    </mat-form-field>
                                    
                                    <ng-container *ngIf="form_avarie_sans_vehicule && select_huile_en_service"><i>Le fluide 'Huile en service' n'est pas autorisé pour un contrôle produit. En effet, si c'est une huile en service alors c'est un contrôle sur véhicule.</i></ng-container>
                                    
                                    <mat-form-field>
                                          <mat-label>Grade SAE</mat-label>
                                          <input matInput type="text" id="gradeSAE" formControlName="gradeSAE" maxlength="20" oninput="this.value = this.value.toUpperCase()">
                                          <mat-hint align="end">{{gradeSAE.value.length}} / 50</mat-hint>
                                          <mat-error *ngIf="gradeSAE?.hasError('required')">Le grade SAE est obligatoire</mat-error>
                                    </mat-form-field> 
                                    
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Combustible</mat-label>
                                          <mat-select name="combustible" id="combustible" formControlName="combustible">
                                                <mat-option *ngFor="let combustible of combustibles$" [value]="combustible">
                                                      {{ combustible.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="combustible?.hasError('required')">La sélection d'un combustible est obligatoire</mat-error>                            
                                    </mat-form-field>
            
                                    <mat-form-field>
                                          <mat-label>Marque du fluide</mat-label>
                                          <input matInput type="text" id="marqueFluide" formControlName="marqueFluide" maxlength="50" oninput="this.value = this.value.toUpperCase()">
                                          <mat-hint align="end">{{marqueFluide.value.length}} / 50</mat-hint>
                                          <mat-error *ngIf="marqueFluide?.hasError('required')">La marque du fluide est obligatoire</mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field>
                                          <mat-label>Type Commercial</mat-label>
                                          <input matInput type="text" id="typeCommercial" formControlName="typeCommercial" maxlength="50" oninput="this.value = this.value.toUpperCase()">
                                          <mat-hint align="end">{{typeCommercial.value.length}} / 50</mat-hint>
                                          <mat-error *ngIf="typeCommercial?.invalid && (typeCommercial?.dirty || typeCommercial?.touched)">{{ typeCommercial.errors?.erreur }}</mat-error>
                                    </mat-form-field> 
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Organe de Prélèvement</mat-label>
                                          <mat-select name="organePrelevement" id="organePrelevement" formControlName="organePrelevement" (selectionChange)="onSelectChangeOrganePrelevement()">
                                                <mat-option *ngFor="let organePrelevement of organesPrelevement$" [value]="organePrelevement">
                                                      {{ organePrelevement.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="organePrelevement?.hasError('required')">La sélection d'un organe de prélèvement est obligatoire</mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Lieu de Prélèvement</mat-label>
                                          <mat-select name="lieuPrelevement" id="lieuPrelevement" formControlName="lieuPrelevement">
                                                <mat-option *ngFor="let lieuPrelevement of lieuxPrelevement$" [value]="lieuPrelevement">
                                                      {{ lieuPrelevement.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="lieuPrelevement?.hasError('required')">La sélection d'un lieu de prélèvement est obligatoire</mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Température de Prélèvement</mat-label>
                                          <mat-select name="temperaturePrelevement" id="temperaturePrelevement" formControlName="temperaturePrelevement">
                                                <mat-option *ngFor="let temperaturePrelevement of temperaturesPrelevement$" [value]="temperaturePrelevement">
                                                      {{ temperaturePrelevement.nom }}
                                                </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="temperaturePrelevement?.hasError('required')">La sélection d'une température de prélèvement est obligatoire</mat-error>                         
                                    </mat-form-field>
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Date de Prélèvement</mat-label>
                                          <input matInput type="date" id="datePrelevement" formControlName="datePrelevement">
                                          <mat-error *ngIf="datePrelevement?.hasError('erreur')">{{ datePrelevement.errors?.erreur }}</mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field *ngIf="!form_huile_neuve">
                                          <mat-label>Kms ou Heures depuis la dernière vidange</mat-label>
                                          <input matInput type="number" id="kmsHeures" formControlName="kmsHeures" min="0" step="1">
                                    </mat-form-field> 
                              </form>  
                        </div>
                  </div>
            </div>
      </div>
      <br>
      <mat-expansion-panel [expanded]="dataSourceAnalyseRetenue.length == 0">
            <mat-expansion-panel-header>
                  <mat-panel-title> Commentaire ou objet de la demande / Pièces jointes / Analyse </mat-panel-title>
            </mat-expansion-panel-header>
            
            <div class="div-container-left" style="align-items:stretch">
                  <div style="width: 33%; display: flex;">
                        <div class="content" style="flex: 1;">
                              <div class="titlebox"></div>
                              <div class="div-container-left" style="display: flex;">
                                    <form [formGroup]="Etape4FormGroup" style="width: 100%; flex: 1; display: flex;">
                                          <mat-form-field class="textarea" style="max-width: 100%; display: flex; flex: 1">
                                                <mat-label>Commentaires</mat-label>
                                                <textarea matInput formControlName = "commentaire" (keyup)="autoGrowTextZone($event)" class="textarea" ></textarea>
                                                <mat-error *ngIf="commentaire?.invalid && (commentaire?.dirty || commentaire?.touched)">{{ commentaire.errors?.erreur }}</mat-error>
                                          </mat-form-field>
                                    </form>
                              </div>
                        </div>
                  </div> 
                  <div style="width: 33%; display: flex;">
                        <div class="content" style="flex: 1">
                              <div class="titlebox">Pièces jointes</div>
                              <div class="div-container-left">
                                    <app-upload-files   (uploadedFiles)  = "getFiles($event)" [uploadParameter]="uploadParameter"></app-upload-files>
                              </div>
                              <div *ngIf="form_adela.tab_PiecesJointes">
                                    <div class = "pjTitle">Pièces jointes :</div>
                                    <div  *ngFor="let file of form_adela.tab_PiecesJointes">
                                        
                                        <a class= "linked" (click)="dowloadFile(file.name)">
                                            {{file.name}}
                                            <br>
                                        </a>
                                                                                        
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div style="width: 34%; display: flex;">
                        <div class="content" style="flex: 1">
                              <div class="titlebox">Analyse</div>
                              <div class="div-container-left">
                                    <div style="max-width: 100%;">
                                          <form [formGroup]="Etape5FormGroup">
                                                <table mat-table  [dataSource]="dataSource" class="table-primary">
                                                      <!-- Selected Column -->
                                                      <ng-container matColumnDef="selecteur">
                                                            <td mat-cell *matCellDef="let analyse" class="td-premiere-colonne" style="font-weight: bold;"> Analyse demandée</td>
                                                      </ng-container>
                  
                                                      <!-- Analyse Column -->
                                                      <ng-container matColumnDef="categorie">
                                                            <td mat-cell *matCellDef="let analyse"> {{analyse.categorie}} </td>
                                                      </ng-container>
                  
                                                      <!-- Détail Column -->
                                                      <ng-container matColumnDef="designation">
                                                            <td mat-cell *matCellDef="let analyse"> {{analyse.designation}} </td>
                                                      </ng-container>
                                    
                                                      <!-- Tarif Column -->
                                                      <ng-container matColumnDef="tarif">
                                                            <td mat-cell *matCellDef="let analyse" style="text-wrap: nowrap;"> {{analyse.tarif}} € </td>
                                                      </ng-container>
                                                      
                                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsAnalyseAEffectuer;"></tr>
                                                </table>
                                          </form>
                                          <form [formGroup]="Etape6FormGroup"  *ngIf="dataSourceAnalyseRetenue?.length">
                                                <table mat-table  [dataSource]="dataSourceAnalyseRetenue" class="table-primary">
                                                      
                                                      <ng-container matColumnDef="selecteur">
                                                            <td mat-cell *matCellDef="let analyseRetenue" class="td-premiere-colonne" style="font-weight: bold;color:green">Analyse retenue</td>
                                                      </ng-container>
      
                                                      <ng-container matColumnDef="categorie">                                                   
                                                            <td mat-cell *matCellDef="let analyseRetenue"> {{analyseRetenue?.categorie}} </td>
                                                      </ng-container>
      
                                                      <ng-container matColumnDef="designation">               
                                                            <td mat-cell *matCellDef="let analyseRetenue"> {{analyseRetenue?.designation}} </td>
                                                      </ng-container>
                                    
      
                                                      <ng-container matColumnDef="tarif">
                                                            <td mat-cell *matCellDef="let analyseRetenue" style="text-wrap: nowrap;"> {{analyseRetenue?.tarif}} € </td>
                                                      </ng-container>
                              
                                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsAnalyseRetenueAdmin;"></tr>
                                                </table>
                                          </form>
                                    </div>
      
                                    <div *ngIf="!form_lecture_seule_analyse_retenue">
                                          <button 
                                                mat-icon-button 
                                                style="text-align: center;" 
                                                color="primary" 
                                                (click)="openSelectionDialog()"
                                                matTooltip="Ouvrir la fenêtre de sélection des analyses"
                                          >
                                                <mat-icon>zoom_in</mat-icon>
      
                                          </button>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>

      </mat-expansion-panel>


      <app-resultat-analyse-edit *ngIf="form_resultat_analyse" 
            [formAdela]="form_adela"
            [form_bsm]="form_bilan_moteur_preventif"
            [destinataire]="Etape1FormGroup.controls.emailExpert.value"
            [dossierFormValid]="!(Etape1FormGroup.invalid || (Etape2FormGroup.invalid && (form_avarie_sur_vehicule || form_huile_neuve || form_bilan_moteur_preventif || form_vehicule_incendie)) || Etape3FormGroup.invalid || Etape4FormGroup.invalid || Etape5FormGroup.invalid)"
            (closeDossier)="closeDossier($event)"
            (saveDossier)="modifierDemandeADELA()"
            (nouvelEtat)="nouvelEtat($event)">
      </app-resultat-analyse-edit>

</mat-card>

<mat-card appearance="outlined" *ngIf="modeCreation == false && !apiUtilitaire.isMobileFormat() && !form_resultat_analyse" class="mat-mdc-card-edition fixed">

      <div class="div-button-center" style="gap: 8px;" >
            
            <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted)">
                  <button mat-flat-button 
                              style       = "text-align: center;" 
                              color       = "primary" 
                              (click)     = "closeDossier(false, false)">
                        <mat-icon>west</mat-icon>
                        <span class="span-button">Fermer</span>
                  </button>
            </div>

            <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted) && form_confirmation_analyse">                                          
                  <button mat-flat-button 
                              style       = "text-align: center;" 
                              color       = "warn" 
                              [disabled]  = "waitingRefused" 
                              (click)     = "refuserDemandeADELA()">
                        <mat-icon>close</mat-icon>
                        <span class="span-button">Refuser la demande</span>
                  </button>
            </div>

            <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted) && form_confirmation_analyse">                                          
                  <button mat-flat-button 
                              style       = "text-align: center;" 
                              color       = "primary" 
                              [disabled]  = "waitingAccepted || dataSourceAnalyseRetenue.length===0"
                              (click)="validerDemandeADELA()">
                        <mat-icon>done</mat-icon>
                        <span class="span-button">Envoyer code VERNOLAB</span>
                  </button>
            </div>
            
            <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted)">                                          
                  <button     mat-flat-button 
                              style       = "text-align: center;" 
                              color       = "primary" 
                              [disabled]  = "waitingModified || dataSourceAnalyseRetenue.length===0" 
                              (click)     = "modifierDemandeADELA()">
                        <mat-icon>save</mat-icon>
                        <span class="span-button">Enregistrer les modifications</span>
                  </button>
            </div>    
            <mat-spinner diameter="24" *ngIf="waitingModified || waiting_Save || waitingRefused || waitingAccepted"></mat-spinner>
      </div>
</mat-card>

<mat-card appearance="outlined" *ngIf="modeCreation == true">
      <div class="footer">
            <i>Les informations collectées vous concernant sont destinées à l'ARD dans le cadre de sa gestion du service ADELA.
            Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée (art. 38 s.), vous disposez d'un droit d'accès, de modification, de rectification et de suppression
            des données vous concernant. Pour toute demande, adressez-vous par courrier à : ARD, 41-43, rue des Plantes, 75014 PARIS ou par courrier électronique à : ard&#64;ffea.fr.</i>
      </div>
</mat-card>
