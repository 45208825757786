import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject, concat, fromEvent, interval, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  private readonly LOCAL_STORAGE_APP_INSTALL_LATER = 'USER_INSTALL_LATER';

  private installAppLaterSub: boolean = false;
  private hasNewVersionSub  : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private canInstallSub     : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private deferredPrompt    : any = null;
  private inApp             : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  

  // Accesseur en lecture de installAppLater
  public get installAppLater$(): boolean {
    return this.installAppLaterSub;
   }

  // Accesseur en lecture de hasNewVersionSub
  public get newVersionFound$(): Observable<boolean> {
    return this.hasNewVersionSub.asObservable();
  }

  // Accesseur en lecture de canInstallSub
  public get canInstallApp$(): Observable<boolean> {
    return this.canInstallSub.asObservable();
  }  

  // Accesseur en lecture de updates
  public get updaterEnabled(): boolean{
    return this.updates.isEnabled;
  }

  // Accesseur en lecture de inApp
  public get isInApp$(): Observable<boolean>{
    return this.inApp.asObservable();
  }

  constructor(appRef          : ApplicationRef 
            , private updates : SwUpdate) { 
              
    this.installAppLaterSub = this.getStorageInstallAppLater();

    // Observable that indicates when the application is stable or unstable.
    const isStable$ = appRef.isStable.pipe(
      tap((isStable: boolean)=> { }),
      first(isStable => isStable === true));
      
    // Observable 6 hours
    const every6h$ = interval(6 * 3600000);

    // Check for updates when app is stable or every 6 hours
    concat(isStable$, every6h$).pipe(
      // True if the Service Worker is enabled (supported by the browser and enabled via ServiceWorkerModule).
      filter(() => this.updates.isEnabled)
    ).subscribe(
      () => { 
        void this.updates.checkForUpdate();
      }
    );

    // Display warning when update is detected
    // this.updates.available.subscribe((evt) => {
    //   this._onUpdateDetected(evt);
    // });

    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.hasNewVersionSub.next(true); 
          this.getUpdate();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });

    updates.versionUpdates.pipe(
)
      // .subscribe((evt) => {
      //   this._onUpdateDetected(evt);
      // });

        
    // Detect if we can add the app to homepage
    fromEvent(window, 'beforeinstallprompt').subscribe(
      (evt:any) => {
        // Avoir calling the install prompt
        evt.preventDefault();

        // Store the event to use it later
        this.deferredPrompt = evt;

        // Signal we can install the app
        this.canInstallSub.next(true);
      });      
  }

/*   // Détecte la disponibilité d'une nouvelle mise à jour
  private _onUpdateDetected(evt: VersionReadyEvent){

    this.hasNewVersionSub.next(true); 
    
    this.getUpdate();
  } */

  public getUpdate() : void { 
    if(!this.hasNewVersionSub.value) {
      return;
    }

    // Activate update then reload the page
    void this.updates.activateUpdate().then(() => document.location.reload());
  }

  // Installe la mise à jour
  public install() : void {
    if(this.deferredPrompt === null) {
      return;
    }

    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then((choice : {"outcome": string}) => {
      if(choice.outcome === 'accepted') {
        this.canInstallSub.next(false);
        this.setStorageInstallAppLater();
      }

      this.deferredPrompt = null;
    });
  }

  public setInApp(value: boolean): void {
    this.inApp.next(value);
  }

  public getStorageInstallAppLater() {    
    return (localStorage.getItem(this.LOCAL_STORAGE_APP_INSTALL_LATER) == "true");   
  }

  public setStorageInstallAppLater() {
    localStorage.setItem(this.LOCAL_STORAGE_APP_INSTALL_LATER, "true");
    this.installAppLaterSub = true;
  }
}
